import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import ApplyForLeave from "./index";
import { useDispatch, useSelector } from "react-redux";
import "./_leaveRequest.scss";
const ModalExample = ({
  title,
  className,
  id,
  open,
  handleClose,
  data,

  ...rest
}) => {
  const user = useSelector((state) => state.user.fetchMe);
  const isRH = user.role?.code === "RH";
  const isEmployee = user.role?.code === "EMPLOYEE";
  const isTeamLead = user.role?.code === "TEAM LEAD";

  const disabled =
    data?.status?.code === "waiting-tech-approval" && isEmployee
      ? false
      : data?.status?.code === "waiting-hr-approval" &&
        isTeamLead &&
        data?.user?._id === user?._id
      ? false
      : (isRH && data?.status?.code === "waiting-tech-approval") ||
        (isRH && data?.status?.code === "waiting-hr-approval")
      ? true
      : true;

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason == "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal applyforleave-modal"
    >
      <DialogTitle id="alert-dialog-title" className="modal_header">
        <span className={className}>
          {!disabled ? "Edit Leave Request" : title}
        </span>
        <div className="close_button">
          <CloseIcon onClick={() => handleClose(id)} />
        </div>
      </DialogTitle>
      <DialogContent>
        <ApplyForLeave
          handleClose={handleClose}
          id={id}
          row={data}
          leaveDetails={true}
          updateMyLeave={true}
          disabled={disabled}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ModalExample;
